"use client";
import CtaButton from "@/modules/common/components/cta-button";

import styles from "./productCardStyles.module.scss";
import WrapperContainer from "../max-width-wrapper";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { truncateString } from "@/lib/util";

const ProductCard = ({ product, index, home = false }) => {
  const getButtonProps = (selectedIndex: number, currentIndex: number) => {
    const isActive = selectedIndex === currentIndex;
    const bgColor = isActive ? "customGreen" : "white";
    const textColor = isActive ? "white" : "black";

    return { bgColor, textColor };
  };

  const router = useRouter();
  return (
    <>
      <div className={`${styles.product_listing_container} w-full `}>
        <WrapperContainer>
          <div
            key={product.id}
            className={`${
              index % 2 ? "flex-row-reverse" : "flex-row"
            } block sm:flex rounded-md gap-10 ${styles.product_listing_card} `}
          >
            {home ? (
              <Link
                style={{ display: "inline-block", width: "100%" }}
                href={"/shop"}
              >
                <img
                  src={product.thumbnail}
                  alt={product.title}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src =
                      "./images/default-product-placeholder.webp";
                  }}
                  className="w-full sm:w-full h-auto bg-[#009E4C33] w-full"
                />
              </Link>
            ) : (
              <img
                src={product.thumbnail}
                alt={product.title}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src =
                    "./images/default-product-placeholder.webp";
                }}
                className="w-full sm:w-1/2 h-auto bg-[#009E4C33]"
              />
            )}
            {/* <img
              src={product.thumbnail}
              alt={product.title}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = "./images/default-product-placeholder.webp";
              }}
              className="w-full sm:w-1/2 h-auto bg-[#009E4C33] "
            /> */}

            <div className="block sm:flex flex-col justify-center w-full">
              <h2 className={`${styles.product_title} text-lg text-black mb-6`}>
                {product.title}
              </h2>
              <div className="flex flex-row space-x-3">
                {product?.coming_soon != "available" && (
                  <div className="flex items-center justify-start">
                    <p className="mb-6 sm:mb-8 font-normal text-base text-white flex items-center justify-center bg-customRed py-1 px-2 rounded">
                      {product?.coming_soon == "coming soon"
                        ? "Coming Soon"
                        : "Pre Order"}
                    </p>
                  </div>
                )}
                {product?.coming_soon != "coming soon" &&
                  product?.is_featured && (
                    <div className="flex items-center justify-start">
                      <p className="mb-6 sm:mb-8 font-normal text-base text-white flex items-center justify-center bg-customRed py-1 px-2 rounded">
                        Featured Product
                      </p>
                    </div>
                  )}

                {product?.supplier && (
                  <div className="flex items-center justify-start">
                    <p className="mb-6 sm:mb-8 font-normal text-base text-black flex items-center justify-center bg-white py-1 px-2 rounded">
                      Vendor: {product.supplier.company}
                    </p>
                  </div>
                )}

                <div className="flex items-center justify-start">
                  <p className="mb-6 sm:mb-8 font-normal text-base text-black flex items-center justify-center bg-white py-1 px-2 rounded">
                    {product.type.value}
                  </p>
                </div>
              </div>
              <p className={`${styles.product_description}  mb-8 sm:mb-14`}>
                {truncateString(product?.description, 320)}
              </p>
              <div className="flex items-center justify-start">
                {home ? (
                  <CtaButton href={`/shop`}>Shop Now</CtaButton>
                ) : (
                  <CtaButton
                    external={product?.external_link != null}
                    href={product?.external_link ?? "/licensee/register"}
                    customClass={
                      product?.coming_soon == "coming soon" ? "disabled" : ""
                    }
                  >
                    {product?.coming_soon == "coming soon"
                      ? "Coming Soon"
                      : product?.coming_soon == "available"
                      ? "Purchase"
                      : "Pre Order"}
                  </CtaButton>
                )}
              </div>
            </div>
          </div>
        </WrapperContainer>
      </div>
    </>
  );
};

export default ProductCard;
